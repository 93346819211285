<template>
  <div style="height: 100%">
    <slot />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("recruiter", ["fetchRecruiterProfile"]),
    ...mapActions("customer", ["fetchCustomer"]),
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("customer", ["customer"]),
  },
  created() {
    if (this.user) {
      if (!this.customer) {
        this.fetchCustomer()
          .then((r) => {
            console.log(`customer`, this.customer?.toObject());
            if (this.customer?.getId() > 0) {
              this.$router.push("/dashboard");
            }
          })
          .catch((err) => {
            if (err.message === "User not part of any customer.") {
              return this.$router.push("/community");
            }
          });
      }
    }
  },
};
</script>